<template>
  <div class="capital">
    <div class="swiper">
      <swiper-nav v-if="images.length > 1" type="prev" class="prev" />
      <swiper-nav v-if="images.length > 1" class="next" type="next" />
      <swiper
        :options="{
          slidesPerView: 'auto',
          touchRatio: 2,
          navigation: {
            nextEl: '.next',
            prevEl: '.prev'
          }
        }"
      >
        <swiper-slide v-for="(img, index) in images" :key="index">
          <img :src="img.path" />
        </swiper-slide>
      </swiper>
    </div>
    <div class="content">
      <h2 class="title">
        Столица Арктики
        <p class="description">
          Крупнейший город России <br /> за Полярным кругом
        </p>
      </h2>
      <a href="#" class="link">
        Подробнее
        <arrows-yellow class="arrow" />
      </a>
      <div class="content-text">
        <h3 class="content-text__title">
          Столица Арктики
        </h3>
        <p class="content-text__text">
          Мурманск — крупнейший город России за Полярным кругом с населением около трехсот тысяч человек, расположенный на берегу незамерзающего Кольского залива Баренцева моря.
        <p class="content-text__text">
          Здесь начинается Великий Северный морской путь, что делает город самым мощным северным портом в Европе, не только торговым и рыбным, но и туристическим.
        </p>
        <p class="content-text__text">
          Мурманск – центр рыбодобывающей и рыбообрабатывающей промышленности Северного бассейна. На город приходится пятая часть всей рыбодобычи России.
          Здесь открываются ворота в Арктику, к ее практически неисчерпаемым минеральным и биологическим ресурсам.
        </p>
        <p class="content-text__text">
          Мурманск с его судоремонтными заводами и другой береговой инфраструктурой – ближайший тыл Северного военно-морского флота, его материально-техническая база и моральная поддержка.
        </p>
        <p class="content-text__special-text">
          От Мурманска рукой подать до Северного полюса:
          <br />
          2272 километра
        </p>
        <p class="content-text__text">
          Ежегодно сотни туристов на атомоходах Мурманского морского пароходства отправляются из Кольского залива на вершину планеты
        </p>
      </div>

      <swiper-image
        :is-navigation="imagesSwiper_1.length > 1"
        :images="imagesSwiper_1"
      />

      <div class="content-text content-text--grey-bg">
        <h3 class="content-text__title">
          Уникальные  природные явления
        </h3>
        <p class="content-text__text">
          Каждый год со 2 декабря по 11 января в течение 40 дней на широте Мурманска устанавливается полярная ночь. В первый день после полярной ночи солнце поднимается над городом всего на 19 минут. Летом в Мурманске наступает полярный день, его продолжительность составляет 62 календарных суток - С 20 мая до середины августа.
        </p>
        <p class="content-text__text">
          Зимой  можно наблюдать Северное сияние – удивительное по своей красоте природное явление.  Северное сияние изображено на гербе Мурманска.
        </p>
      </div>

      <swiper-image
        :is-navigation="imagesSwiper_2.length > 1"
        :images="imagesSwiper_2"
        :color-bg="'grey'"
      />

      <div class="content-text">
        <h3 class="content-text__title">
          В гостях у сказки — Кольский полуостров
        </h3>
        <p class="content-text__text">
          Из Мурманска без проблем можно добраться до Терского берега, чтобы поймать царицу северных рек – сёмгу, увидеть красоту подводного мира Белого и Баренцева морей.
        </p>
        <p class="content-text__text">
          В мае у берегов Кольского полуострова можно увидеть китов -  косаток и малых полосатиков.
        </p>
        <p class="content-text__text">
          Можно побывать в Печенгском районе, на Кольской сверхглубокой скважине (пробурено свыше 12000 метров), пройти по лыжне Дружбы через территории трех пограничных стран (России, Норвегии и Финляндии), а в Ловозерском районе познакомиться с бытом и культурой древнего саамского народа.
        </p>
        <p class="content-text__special-text">
          Кольский полуостров — это
          <br />
          тундра, арктический ветер,
          <br />
          следы Великой
          <br />
          Отечественной войны и
          <br />
          ледяные волны океана.
        </p>
        <p class="content-text__text">
          Одна из главных достопримечательность Кольского полуострова — Хибины. Это невысокие древние горы, испещрённые туристическими и альпинистскими маршрутами. Хибинская панорама не похожа на привычные виды гор, здесь нет альпийских пейзажей с острыми вершинами. Зато эта горная страна легко узнаваема по многочисленным плато, тёмным каменистым склонам и эффектным циркам.
        </p>
      </div>

      <swiper-image
        :is-navigation="imagesSwiper_3.length > 1"
        :images="imagesSwiper_3"
      />

    </div>
  </div>
</template>

<script>
import SwiperNav from "@/components/Parts/SwiperNav";
import ArrowsYellow from "@/components/Icons/ArrowsYellow";
import SwiperImage from "@/components/Parts/SwiperImage";

export default {
  name: "CapitalOfTheNorth",
  components: {
    SwiperNav,
    ArrowsYellow,
    SwiperImage
  },
  data() {
    return {
      images: [
        {
          path: require("@/assets/img/capital/tile-2.jpg")
        },
      ],
      imagesSwiper_1: [
        {
          path: require("@/assets/img/capital/swiper1/tile-1.jpg"),
          title: "Мурманск - столица заполярья"
        },
      ],
      imagesSwiper_2: [
        {
          path: require("@/assets/img/capital/swiper2/tile-1.jpg"),
          title: "Северное сияние"
        },
      ],
      imagesSwiper_3: [
        {
          path: require("@/assets/img/capital/swiper3/tile-1.jpg"),
          title: "Уникальная красота северной природы"
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.capital {
  position: relative;
  height: 1172px;
  overflow-x: hidden;
  overflow-y: auto;

  .swiper {
    .next {
      position: absolute;
      right: -174px;
      top: 274px;
      z-index: 10;
    }

    .prev {
      position: absolute;
      left: -174px;
      top: 274px;
      z-index: 10;
    }

    .swiper-slide {
      width: 100%;
      height: 803px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bullets {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 64px;

      .bullet {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        opacity: 0.3;
        background-color: #f3f3f3;
        margin: 0 12px;

        &.active {
          opacity: 1;
          background-color: $color_yellow;
        }
      }
    }
  }

  .article {
    width: 832px;
    margin: 0 auto;
    margin-bottom: 120px;
    font-size: 32px;
    line-height: 1.44;
    letter-spacing: 0.45px;
    color: rgba(#fff, 0.8);
    h1 {
      font-size: 82px;
      font-weight: 500;
      letter-spacing: -0.66px;
      line-height: normal;
      color: rgba(#fff, 0.95);
      margin-bottom: 32px;
    }
    h2 {
      font-size: 40px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.23px;
      margin-bottom: 32px;
    }
    p {
      margin-bottom: 32px;
    }
  }

  .content {
    .title {
      padding-top: 74px;

      .description {
        padding-top: 51px;
      }
    }
  }
}
</style>
